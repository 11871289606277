























































































































































































































































































































































import { Vue, Component, Watch, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { isArray, last, sortBy } from 'lodash';
import CardDataList from '@/components/card-data-list.vue';
import { ISimpleTableColumn } from '@/interfaces/simple-table-column.interface';
import CrmClientDetailsCard from '@/components/crm/client-details-card.vue';
import ClientModel from '@/models/crm/client.model';
import ClientService from '@/services/crm/client.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ClientStatusEnum } from '@/enums/crm/client-status.enum';
import RouterService from '@/services/router.service';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ContentDialog from '@/components/content-dialog.vue';
import CrmEmailView from '@/components/crm/email-view.vue';
import ContactService from '@/services/crm/contact.service';
import CrmContactForm from '@/components/crm/contac-form.vue';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import ProcessFlowModel from '@/models/crm/process-flow.model';
import ProcessService from '@/services/crm/process.service';
import CrmNewKanbanViewer from '@/components/crm/new-kanban-viewer.vue';
import ProcessModel from '@/models/crm/process.model';
import ContactModel from '@/models/crm/contact.model';
import CrmActivityView from '@/components/crm/activity-view.vue';
import ActivityService from '@/services/crm/activity.service';
import ActivityEventModel from '@/models/crm/activity-event.model';
import SessionService from '@/services/session.service';
import CrmChatCallerButton from '@/components/crm/chat-caller-button.vue';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import CrmProspectForm from '@/components/crm/prospect-form.vue';
import ProspectService from '@/services/crm/prospect.service';
import SettingsModel from '@/models/crm/settings.model';
import ClientManagementService from '@/services/crm/client-management.service';
import ClientManagementModel from '@/models/crm/client-management.model';
import AttendanceService from '@/services/crm/attendance.service';
import ResponsibleAttendantModel from '@/models/crm/responsible-attendant.model';
import SettingsService from '@/services/crm/settings.service';
import CardContact from '@/components/card-contact.vue';
import OriginModel from '@/models/crm/origin.model';
import ContactTypeModel from '@/models/crm/contact-type.model';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { StringHelper } from '@/utils/helpers/string-helper';

interface IEvent {
  id: number;
  description: string;
  date: string;
  contact: string;
  type: string;
}

interface IProcess {
  id: number;
  idFluxo: number;
  description: string;
  step: string;
  idFila: number;
  date: string;
  contact: string;
  allowDetail: boolean;
  hasFlowStep: boolean;
  hasFlowCurrentStep: boolean;
}

interface IContact {
  id: number;
  email: string;
  phone: string;
  type: string | null;
  contact: string;
  model: ContactModel;
}

type DataGridFilterConfig = {
  typeContact: ContactTypeModel[];
  contactChannel: OriginModel[];
  situation: number;
};

@Component({
  components: {
    CardDataList,
    CrmClientDetailsCard,
    ContentDialog,
    ConfirmationDialog,
    CrmProspectForm,
    CrmEmailView,
    CrmContactForm,
    CrmNewKanbanViewer,
    CrmActivityView,
    CrmChatCallerButton,
    CardContact,
    DataGridFilter,
  },
})
export default class CrmDashboard extends Vue {
  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmProcessService)
  private processService!: ProcessService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmClientManagementService)
  private clientManagementService!: ClientManagementService;

  @inject(InjectionIdEnum.AttendanceService)
  private attendanceService!: AttendanceService;

  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  @InjectReactive('userContactInfo') readonly userContactInfo!: UserContactInfo;

  @InjectReactive('settings') settings!: SettingsModel; // Removido readonly no ATD-16689

  attendantsList: ResponsibleAttendantModel[] = [];

  client: ClientModel = new ClientModel();

  currentContact!: ContactModel;

  processesList: ProcessModel[] = [];

  contactsList: ContactModel[] = [];

  eventsList: ActivityEventModel[] = [];

  idFila = -1;

  clientManagementSituation = '';

  loadingSummary = false;

  loadingProcesses = false;

  loadingContacts = false;

  loadingEvents = false;

  disableCards = false;

  clientNotIntegrated = false;

  disableParticularities = false;

  clientDetailsActionsLoading = false;

  disabledPhone = false;

  disabledWhatsApp = false;

  filters: DataGridFilterConfig = {
    typeContact: new Array<ContactTypeModel>(),
    contactChannel: new Array<OriginModel>(),
    situation: 1,
  };

  contactTypeOptions: ContactTypeModel[] = [];

  channelOptions: OriginModel[] = [];

  contactsListFilter!: ContactModel[] | null;

  isVisible = false;

  emailAddressee!: string;

  contactStatusFilterOptions: { id: number; nome: string }[] = [
    {
      id: 1,
      nome: this.activeContactStatusDescription,
    },
    {
      id: 2,
      nome: this.inactiveContactStatusDescription,
    },
    {
      id: -1,
      nome: this.allStatusDescription,
    },
  ];

  eventDataListColumns: ISimpleTableColumn[] = [
    {
      name: `${this.$t('crm.view.dashboard.schedule')}`,
      prop: 'description',
    },
    {
      name: `${this.$t('crm.view.dashboard.dateAndHour')}`,
      prop: 'date',
      width: '150px',
    },
    {
      name: `${this.$t('crm.view.dashboard.attendant')}`,
      prop: 'contact',
    },
    {
      name: `${this.$t('crm.view.dashboard.historyType')}`,
      prop: 'type',
    },
    {
      name: `${this.$t('crm.view.dashboard.status')}`,
      prop: 'status',
    },
  ];

  processDataListColumns: ISimpleTableColumn[] = [
    {
      name: `${this.$t('crm.view.dashboard.name')}`,
      prop: 'description',
    },
    {
      name: `${this.$t('crm.view.dashboard.lastStep')}`,
      prop: 'step',
    },
    {
      name: `${this.$t('crm.view.dashboard.dateAndHour')}`,
      prop: 'date',
      width: '150px',
    },
    {
      name: `${this.$t('crm.view.dashboard.attendant')}`,
      prop: 'contact',
    },
  ];

  colors = new Map([
    ['eventBlue', 'rgb(58, 83, 155)'],
    ['eventYellow', 'rgb(247, 202, 24)'],
    ['eventGreen', 'rgb(30, 130, 76)'],
    ['eventRed', 'rgb(192, 57, 43)'],
    ['eventOrange', 'rgb(249, 105, 14)'],
    ['eventPurple', 'rgb(145, 61, 136)'],
  ]);

  dialogConfig: IKeyValue<IDialogConfig> = {
    confirmation: {
      message: '',
      color: '',
      show: false,
      onChoice: () => {},
    },
    sendEmail: {
      show: false,
    },
    contactForm: {
      show: false,
      id: null,
      cnpj: null,
      hasExistingContacts: false,
    },
    processKanban: {
      show: false,
      idFluxo: null,
      nomeFluxo: null,
      process: null,
    },
    activityView: {
      show: false,
      id: null,
    },
    prospect: {
      show: false,
      id: null,
      hasExistingContacts: false,
    },
    particularities: {
      show: false,
      editing: false,
      saving: false,
      description: '',
    },
  };

  processFlowOptions: ProcessFlowModel[] = [];

  async created(): Promise<void> {
    // Tratativa feita para o ATD-16689, o Kanban não chama o index.vue
    if (this.settings === null) {
      this.settings = await this.settingsService.getSettings();
    }
  }

  async mounted(): Promise<void> {
    this.contactsListFilter = null;
    this.loadProcessFlows();
    this.loadClientSummary();
    this.loadContacts();
    this.loadProcesses();
    this.loadEvents();
    this.loadAttendants();
    this.loadContactTypes();
    this.loadOrigins();
    this.disableButtonsContact();
  }

  @Watch('$route')
  async watchRoute(): Promise<void> {
    // Tratativa feita para o ATD-16689, o Kanban não chama o index.vue
    if (this.settings === null) {
      this.settings = await this.settingsService.getSettings();
    }

    this.loadClientSummary();
    this.loadContacts();
    this.loadProcesses();
    this.loadEvents();
    this.loadAttendants();
    this.loadContactTypes();
    this.loadOrigins();
  }

  onSendEmail(email: string): void {
    this.emailAddressee = email;
    this.dialogConfig.sendEmail.show = true;
  }

  // #endregion Contact

  onAddContact(): void {
    this.dialogConfig.contactForm.id = null;
    this.dialogConfig.contactForm.show = true;
  }

  async onEditContact(item: IContact): Promise<void> {
    this.dialogConfig.contactForm.id = item.id;
    this.dialogConfig.contactForm.show = true;
  }

  async onAfterSaveContact(model: ContactModel, saved: boolean): Promise<void> {
    this.dialogConfig.contactForm.hasExistingContacts = false;
    if (saved) this.loadContacts();
    if (this.settings.flagEnviaContatoErp && model.idProspect == null && model.cnpj !== null) {
      const loader = this.$loading.show();
      try {
        this.$notify.info(`${this.$t('crm.view.dashboard.contact.message.sendToErp')}`);
        const res = await this.contactService.sendToErp(model);
        this.$notify.success(res);
      } catch (err) {
        const message = err && (err as Error).message;
        this.$notify.error(`${message}`);
      } finally {
        loader.hide();
      }
    }
  }

  async onDeleteContact(item: IContact): Promise<void> {
    this.beforeDeleteConfirmation(`${this.$t('global.youAreSureDeleteRecord')}`, async (accept: boolean) => {
      if (accept) {
        const currentList = this.contactsList;

        const loader = this.$loading.show();
        try {
          this.contactsList = this.contactsList.filter((x) => x.id !== item.id);

          await this.contactService.delete(item.id);
        } catch (err) {
          this.contactsList = currentList;

          this.$notify.error(err && (err as Error).message);
        } finally {
          loader.hide();
          this.dialogConfig.contactForm.show = false;
        }
      }
    });
  }

  // #endregion

  // #region Process

  onAddProcess(flow: ProcessFlowModel): void {
    /*
     O trecho abaixo está comentado pois essas alterações são apenas um paliativo temporário
     da questão de não abrir o kanban por dentro do Vue
     */
    // this.dialogConfig.processKanban.idFluxo = flow.id;
    // this.dialogConfig.processKanban.nomeFluxo = flow.nome;
    // this.dialogConfig.processKanban.clientId = this.client.codCliente;
    // this.dialogConfig.processKanban.clientType = this.clientType;
    // this.idFila = -1;
    // this.dialogConfig.processKanban.show = true;
    const kanbanUrl = this.processService.getNewKanbanUrl(
      flow.id,
      this.client.codCliente,
      this.clientType,
      -1,
      0,
      true,
      this.client.representante.codigo,
    );

    window.open(kanbanUrl, '_blank');
  }

  async onEditProcess(item: IProcess): Promise<void> {
    if (item.hasFlowCurrentStep || (item.allowDetail && item.hasFlowStep)) {
      this.dialogConfig.processKanban.idFluxo = item.idFluxo;
      this.dialogConfig.processKanban.nomeFluxo = item.description;

      this.dialogConfig.processKanban.process = item;
      this.idFila = item.idFila;
      this.dialogConfig.processKanban.clientId = this.client.codCliente;
      this.dialogConfig.processKanban.clientType = this.clientType;
      this.dialogConfig.processKanban.show = true;
    } else {
      this.$notify.error(this.$t('crm.view.dashboard.messages.detailNotAuthorized'));
    }
  }

  onAfterKanbanClose(): void {
    this.dialogConfig.processKanban.process = null;
    this.loadProcesses();
  }

  get showAddProcess(): boolean {
    return !!this.processFlowOptions.length && !this.sessionService.builtInMode;
  }

  get showEditProcess(): boolean {
    return !this.sessionService.builtInMode;
  }

  get isConvertedProspect(): boolean {
    return this.client.situacao === ClientStatusEnum.Converted;
  }

  get activeStatusDescription(): string {
    return this.$t('crm.view.dashboard.contact.status.active').toString();
  }

  get activeContactStatusDescription(): string {
    return this.$t('crm.view.dashboard.contact.status.activeContacts').toString();
  }

  get inactiveStatusDescription(): string {
    return this.$t('crm.view.dashboard.contact.status.inactive').toString();
  }

  get inactiveContactStatusDescription(): string {
    return this.$t('crm.view.dashboard.contact.status.inactiveContacts').toString();
  }

  get allStatusDescription(): string {
    return this.$t('crm.view.dashboard.contact.status.both').toString();
  }

  // #endregion

  // #region Activity

  onAddEvent(): void {
    this.dialogConfig.activityView.id = null;
    this.dialogConfig.activityView.show = true;
  }

  onEditEvent(item: IEvent): void {
    this.dialogConfig.activityView.id = item.id;
    this.dialogConfig.activityView.show = true;
  }

  onAfterActivityViewClose(): void {
    this.loadEvents();
  }

  // #endregion

  // #region Prospect

  onEditProspect(model: ClientModel): void {
    this.dialogConfig.prospect.id = parseInt(model.codCliente, 10);
    this.dialogConfig.prospect.show = true;
  }

  async onTransformProspect(model: ClientModel): Promise<void> {
    if (model?.codCliente) {
      this.clientDetailsActionsLoading = true;

      try {
        const result = await this.prospectService.transformToClient(this.client.codCliente);
        this.client.situacao = ClientStatusEnum.Converted;
        this.activeClient.situacao = ClientStatusEnum.Converted;

        this.$notify.success(result, 8000);
      } catch (err) {
        this.client.situacao = ClientStatusEnum.ConversionFailed;
        this.activeClient.situacao = ClientStatusEnum.ConversionFailed;
        this.$notify.error(err && (err as Error).message, 5000);
      } finally {
        this.clientDetailsActionsLoading = false;
      }
    }
  }

  onDeleteProspect(model: ClientModel): void {
    this.beforeDeleteConfirmation(`${this.$t('global.youAreSureDeleteRecord')}`, async (accept: boolean) => {
      if (accept) {
        this.clientDetailsActionsLoading = true;

        try {
          await this.prospectService.delete(model.codCliente);

          this.$notify.success(this.$t('crm.view.dashboard.messages.prospectSuccessfullyDeleted'));
          this.routerService.navigate({ name: 'CrmHome' });
        } catch (err) {
          if (err && (err as Error).message.toLowerCase().includes('constraint')) {
            this.$notify.error(this.$t('crm.view.dashboard.messages.prospectNotDeletedConstraint'));
            return;
          }
          this.$notify.error(this.$t('crm.view.dashboard.messages.prospectNotDeleted'));
        } finally {
          this.clientDetailsActionsLoading = false;
        }
      }
    });
  }

  onOpenParticularities(): void {
    this.dialogConfig.particularities.show = true;
  }

  onCloseParticularities(): void {
    if (this.dialogConfig.particularities.editing && !this.dialogConfig.particularities.saving) {
      this.dialogConfig.particularities.description = this.activeClient.particularidades || '';
    }
    this.dialogConfig.particularities.editing = false;
    this.dialogConfig.particularities.show = false;
  }

  async onSaveParticularities(): Promise<void> {
    this.dialogConfig.particularities.saving = true;
    const loader = this.$loading.show();
    try {
      await this.clientService.saveParticularities(
        this.activeClient.cnpjCpf === '' ? null : this.activeClient.cnpjCpf,
        this.activeClient.type === ClientTypeEnum.Prospect ? this.activeClient.codCliente : null,
        this.activeClient.type,
        (this.dialogConfig.particularities.description as string),
      );
      this.activeClient.particularidades = (this.dialogConfig.particularities.description as string);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.onCloseParticularities();
      this.dialogConfig.particularities.saving = false;
      loader.hide();
      this.$notify.success(this.$t('crm.view.dashboard.dialog.particularities.saved',
        { clientType: this.activeClient.tipo?.toLowerCase() }));
    }
  }

  onAfterSaveProspect(): void {
    this.dialogConfig.prospect.hasExistingContacts = false;
    this.dialogConfig.prospect.id = null;
    this.dialogConfig.prospect.show = false;

    this.loadClientSummary();
  }

  // #endregion

  beforeDeleteConfirmation(message: string, onChoice: CallableFunction): void {
    this.dialogConfig.confirmation.message = message;
    this.dialogConfig.confirmation.color = 'red';
    this.dialogConfig.confirmation.onChoice = onChoice;
    this.dialogConfig.confirmation.show = true;
  }

  get eventDataListItems(): IEvent[] {
    return this.eventsList.map((item) => {
      let styleNameAux = item.styleName;
      if (item.styleName === 'eventCyan') {
        styleNameAux = 'eventYellow';
      }

      return {
        id: item.id,
        description: item.titulo,
        date: `${this.$d(item.dataHoraInicio, item.timed ? 'dateTime' : 'short')}`,
        contact: item.atendente?.nome || '',
        type: item.tipoHistorico?.nome,
        status: styleNameAux ? this.$t(`crm.view.dashboard.statusByEventColor.${styleNameAux}`) : '',
        color: styleNameAux,
      };
    });
  }

  get processDataListItems(): IProcess[] {
    return this.processesList.map((item) => ({
      id: item.idProcesso,
      idFluxo: item.idFluxo,
      description: item.fluxo,
      date: `${this.$d(item.data, 'dateTime')}`,
      step: item.etapa,
      idFila: item.idFila,
      contact: item.atendente,
      allowDetail: item.permiteDetalharQualquerEtapa,
      hasFlowStep: item.temFluxoEtapaUsuario,
      hasFlowCurrentStep: item.temFluxoEtapaUsuarioEtapaAtual,
    }));
  }

  get contactFormDialogTitle(): string {
    let titleKey!: string;
    if (this.dialogConfig.contactForm.hasExistingContacts) {
      titleKey = 'crm.view.dashboard.dialog.contactForm.existingContactsTitle';
    } else {
      titleKey = this.dialogConfig.contactForm.id
        ? 'crm.view.dashboard.dialog.contactForm.editTitle'
        : 'crm.view.dashboard.dialog.contactForm.newTitle';
    }
    return `${this.$t(titleKey)}`;
  }

  get prospectFormDialogTitle(): string {
    let titleKey!: string;
    if (this.dialogConfig.prospect.hasExistingContacts) {
      titleKey = 'crm.view.dashboard.dialog.contactForm.existingContactsTitle';
    } else titleKey = 'crm.view.dashboard.dialog.prospect.title';
    return `${this.$t(titleKey)}`;
  }

  get processKanbanDialogTitle(): string {
    return `${this.$t('crm.view.dashboard.dialog.processKanban.title',
      { flow: this.dialogConfig.processKanban.nomeFluxo })}`;
  }

  get editaParticularidades(): boolean {
    return this.settings && this.settings.flagPermiteEditarParticularidades
      ? !this.settings.flagPermiteEditarParticularidades : true;
  }

  private async loadContacts(): Promise<void> {
    this.loadingContacts = true;
    try {
      // Tratativa feita para o ATD-16689, o Kanban não chama o index.vue
      if (this.settings === null) {
        this.settings = await this.settingsService.getSettings();
      }

      this.contactsList = await this.contactService.getContacts(
        await this.getClientIdFromRoute(),
        this.clientType,
        this.settings.flagPermiteClientesSituacao99Crm360,
      );
      this.disableCards = false;
    } catch (err) {
      this.processesList = [];
      this.contactsList = [];
      this.eventsList = [];
      this.disableCards = true;
      this.disableParticularities = true;
      this.clientNotIntegrated = true;
      this.$notify.error(this.$t('crm.navigation.messages.errorIntegrationClientNotFound'));
    } finally {
      this.loadingContacts = false;
    }
  }

  private async loadProcessFlows(): Promise<void> {
    this.processFlowOptions = await this.processService.getFlows();
  }

  private async loadProcesses(): Promise<void> {
    this.loadingProcesses = true;
    try {
      this.processesList = await this.processService.getProcesses(
        await this.getClientIdFromRoute(),
        this.clientType,
        3,
      );
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.loadingProcesses = false;
    }
  }

  private getKanbanUrl(item: IProcess): string {
    return this.processService.getNewKanbanUrl(
      item.idFluxo,
      this.client.codCliente,
      this.client.type,
      item.idFila,
      item.id,
      true,
    );
  }

  private async loadEvents(): Promise<void> {
    this.loadingEvents = true;
    try {
      this.eventsList = await this.activityService.getSummaryEvents(
        await this.getClientIdFromRoute(),
        this.clientType,
        5,
      );
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.loadingEvents = false;
    }
  }

  private async loadClientSummary(): Promise<void> {
    const clientId = await this.getClientIdFromRoute();
    if (clientId) {
      this.loadingSummary = true;
      const loader = this.$loading.show();
      try {
        const result = await Promise.all([
          this.getLastClientManagement(clientId),
          this.clientService.get(clientId, this.clientType),
        ]);

        const clientsManagementTaskIndex = 0;
        const lastClientManagement = result[clientsManagementTaskIndex];
        this.clientManagementSituation = lastClientManagement?.situation || '';
        const clientTaskIndex = 1;
        if (!result[clientTaskIndex].atendenteChave) {
          result[clientTaskIndex].atendenteChave = null;
        }
        this.client = result[clientTaskIndex];
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.loadingSummary = false;
        loader.hide();
      }
      this.dialogConfig.particularities.description = this.activeClient.particularidades;
    }
  }

  private async getLastClientManagement(clientId: string): Promise<ClientManagementModel | undefined> {
    if (this.clientType === ClientTypeEnum.Prospect) {
      return undefined;
    }

    try {
      const clientIdParam = this.activeClient ? this.activeClient?.cnpjCpf : clientId;
      const clientsManagement = await this.clientManagementService.getClientsManagement(clientIdParam);
      return last(sortBy(clientsManagement || [], 'collectionId'));
    } catch (err) {
      // TODO: remove comment below on release "Gestão de Clientes" in production
      // this.$notify.error(err && (err as Error).message);
    }
    return undefined;
  }

  private async getClientIdFromRoute(): Promise<string> {
    if (this.clientType === ClientTypeEnum.Prospect) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    if (!this.activeClient) {
      const activeClient = await this.clientService
        .get(this.routerService.route().params.clientId, this.clientType)
        .then((cliente) => cliente);
      if (activeClient.type) {
        return activeClient.type.toString().toLowerCase() === ClientTypeEnum.Client
          ? activeClient.cnpjCpf
          : activeClient.codCliente;
      }
    }
    return this.activeClient.cnpjCpf;
  }

  private async loadAttendants() {
    this.attendantsList = await this.attendanceService.getAttendantList();
    this.attendantsList.sort((a, b) => a.usuario.localeCompare(b.usuario));
    this.attendantsList.unshift({ id: null, usuario: '- Sem Responsável -' });
  }

  private async onSetResponsibleAttendant(attendantId: number | null) {
    try {
      const dataToSave = {
        cnpjCpf: this.client.cnpjCpf,
        crmUsuarioId: attendantId ?? 0,
        prospect: this.client.prospectId,
        clientType: this.clientType,
      };

      await this.attendanceService.setResponsibleAttendant(dataToSave);

      this.$notify.success(this.$t('crm.view.dashboard.responsibleAttendant.success'));
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
      this.client.atendenteChave = null;
    }
  }

  async onIntegrateClient(model: ClientModel): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.clientService.integrateSingleClient(model.cnpjCpf.replace(/\D/g, ''));

      this.$notify.success(this.$t('crm.view.dashboard.messages.integrateClientSuccessfully'));
      setTimeout(() => this.$router.go(0), 2500);
    } catch (err) {
      this.$notify.error(this.$t('crm.view.dashboard.messages.integrateClientError'));
    } finally {
      loader.hide();
    }
  }

  private disableButtonsContact() {
    if (!this.settings.flagWhatsApp360Ativo) {
      this.disabledWhatsApp = true;
    }

    const { isCloudTalkPartner } = this.settings;
    const { callCenterAgentId } = this.sessionService;
    const agentIdIsEmpty = StringHelper.isBlank(callCenterAgentId);
    if (!this.settings.flagUtilizaTelefonia || (isCloudTalkPartner && agentIdIsEmpty)) {
      this.disabledPhone = true;
    }
  }

  get activeFilters(): number {
    let active = 0;
    const filtersToIgnore: string[] = [];
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      const filter = this.filters[key];
      switch (key) {
        default:
          if (!filtersToIgnore.includes(key) && filter && !(isArray(filter) && !filter.length)) {
            active += 1;
          }
      }
    });

    return active;
  }

  private async loadContactTypes(): Promise<void> {
    this.contactTypeOptions = await this.contactService.getContactTypes();
  }

  private async loadOrigins(): Promise<void> {
    this.channelOptions = sortBy(await this.activityService.getOrigins(), 'descricao');
  }

  public openFilters() {
    if (this.isVisible === false) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }

  public filtroInicial(): ContactModel[] {
    return this.contactsList.filter((contato) => contato.flagAtivo === 1);
  }

  public onFilterChange() {
    let filteredList = this.contactsList;

    if (this.filters.situation >= 0) {
      if (this.filters.situation === 2) {
        filteredList = filteredList
          .filter((contato) => contato.flagAtivo === 0);
      } else {
        filteredList = filteredList
          .filter((contato) => contato.flagAtivo === 1);
      }
    }

    if (this.filters.typeContact.length >= 1) {
      filteredList = filteredList.filter((contato) => contato.tiposContato != null
        && contato.tiposContato.map((i) => i.id)
          .some((tipo) => this.filters.typeContact.map((i) => i.id).includes(tipo)));
    }

    if (this.filters.contactChannel.length >= 1) {
      filteredList = filteredList.filter((contato) => contato.canaisContato.map((i) => i.id)
        .some((tipo) => this.filters.contactChannel.map((i) => i.id).includes(tipo)));
    }

    this.contactsListFilter = filteredList;
  }
}
